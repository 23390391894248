import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import withRoot from "../withRoot";
import Header from "../component/header";

const WeeklyTechRead = ({ pageContext, classes }) => {
  const { articles, year, week } = pageContext;
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Weekly tech read ${year} week ${week} - Sébastien Viande - Developper FullStack`}
        </title>
        <meta name="description" content="Personal website, portfolio, blog." />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <Header />
      <Paper id="main" className={classes.main}>
        <Typography color="primary" align="center" variant="h1" gutterBottom>
          {`Weekly tech read ${year} week ${week}`}
        </Typography>
        <Typography
          color="primary"
          align="center"
          gutterBottom
          className={classes.backToList}
        >
          <Link to={`/weekly-tech-read`}>back to week list</Link>
        </Typography>

        {articles.map((article) => {
          return (
            <Fragment key={article.id}>
              <Grid container direction="column" className={classes.article}>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <a
                      href={article.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {article.title}
                    </a>{" "}
                    -{" "}
                    <Typography variant="caption" color="textSecondary">
                      {article.reading_time}min read
                    </Typography>
                  </Grid>
                  <Grid item>
                    {article.tags.map((tag) => (
                      <Chip
                        className={classes.link}
                        component={Link}
                        to={`/weekly-tech-read/tags/${tag.label}`}
                        key={tag.slug}
                        size="small"
                        label={tag.label}
                        color="primary"
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Paper>
    </Fragment>
  );
};

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    padding: spacing(2),
  },
  article: {
    marginBottom: "1rem",
  },
  link: {
    cursor: "pointer",
  },
  backToList: {
    marginBottom: spacing(4),
  },
});

export default withRoot(withStyles(styles)(WeeklyTechRead));
